exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-admin-content-[slug]-js": () => import("./../../../src/pages/admin/content/[slug].js" /* webpackChunkName: "component---src-pages-admin-content-[slug]-js" */),
  "component---src-pages-admin-content-model-course-js": () => import("./../../../src/pages/admin/content-model/course.js" /* webpackChunkName: "component---src-pages-admin-content-model-course-js" */),
  "component---src-pages-admin-content-model-image-js": () => import("./../../../src/pages/admin/content-model/image.js" /* webpackChunkName: "component---src-pages-admin-content-model-image-js" */),
  "component---src-pages-admin-content-model-index-js": () => import("./../../../src/pages/admin/content-model/index.js" /* webpackChunkName: "component---src-pages-admin-content-model-index-js" */),
  "component---src-pages-admin-content-model-video-js": () => import("./../../../src/pages/admin/content-model/video.js" /* webpackChunkName: "component---src-pages-admin-content-model-video-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-login-js": () => import("./../../../src/pages/join/login.js" /* webpackChunkName: "component---src-pages-join-login-js" */),
  "component---src-pages-join-sign-up-js": () => import("./../../../src/pages/join/sign-up.js" /* webpackChunkName: "component---src-pages-join-sign-up-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-zanka-academy-courses-[slug]-js": () => import("./../../../src/pages/zanka-academy/courses/[slug].js" /* webpackChunkName: "component---src-pages-zanka-academy-courses-[slug]-js" */),
  "component---src-pages-zanka-academy-courses-index-js": () => import("./../../../src/pages/zanka-academy/courses/index.js" /* webpackChunkName: "component---src-pages-zanka-academy-courses-index-js" */),
  "component---src-pages-zanka-academy-index-js": () => import("./../../../src/pages/zanka-academy/index.js" /* webpackChunkName: "component---src-pages-zanka-academy-index-js" */)
}

